<template>
  <div class="report-list">
    <div class="title-box">
      <div class="title-box__icon">
        <img src="../../../assets/icons/dashboard/Report List.png" />
        <span>Inspection Report List</span>
      </div>
    </div>

    <div class="report-list__wrap">
      <div
        class="report-list__wrap__item _flex _flex-align__center _flex-justify__between"
        v-for="(item, index) in list"
        :key="index"
        @click="$router.push({ path: '/view-pdf', query: { src: item.url } })"
      >
        <span class="_font-size__sub__heading">{{ item.label }}</span>
        <el-button type="info">Download</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { props } from './props'

export default {
  props,
  data() {
    return {
      list: []
    }
  },
  watch: {
    detail: {
      handler(val) {
        this.list = []
        if (val.reportFilePath) {
          this.list.push({
            label: val.reportFileRelativeName.match(/[\w.]+/)[0],
            url: val.reportFilePath
          })
        }
      },
      deep: true
    }
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
.report-list {
  &__wrap {
    &__item {
      width: 100%;
      padding: 8px 0;
      border-bottom: 1px solid var(--color-info-light-7);

      &:first-of-type {
        padding-top: 0;
      }
      &:last-of-type {
        border-bottom: none;
        padding-bottom: 0;
      }
    }
  }
}
</style>
